@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/inter-v12-latin-regular.eot'); /* IE9-compatible modes */
  src: url('./fonts/inter-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/inter-v12-latin-regular.woff2') format('woff2'), /* Super modern browsers */
       url('./fonts/inter-v12-latin-regular.woff') format('woff'), /* Modern browsers */
       url('./fonts/inter-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/inter-v12-latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/inter-v12-latin-700.eot'); /* IE9-compatible modes */
  src: url('./fonts/inter-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/inter-v12-latin-700.woff2') format('woff2'), /* Super modern browsers */
       url('./fonts/inter-v12-latin-700.woff') format('woff'), /* Modern browsers */
       url('./fonts/inter-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/inter-v12-latin-700.svg#Inter') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
